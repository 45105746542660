import React from "react";
import { isDevelopment } from "@biblioteksentralen/js-utils";
import { useRouter } from "next/router";
import Script from "next/script";
import { Role } from "@sanity/types";

import { useSanityAuth } from "../../components/sanityAuth/useSanityAuth";
import { isProductionDataset } from "../currentDataset";

const isE2eTestEmail = (email?: string): boolean => !!email?.match(/e2e-test/i);
const isBibsentEmail = (email?: string): boolean => !!email?.match(/bibsent.no/i);
const isRediaEmail = (email?: string): boolean => !!email?.match(/redia.dk/i);
const hasAdminRole = (roles?: Role[]): boolean => !!roles?.find(({ name }) => name === "administrator");

// This is a temporary heuristic-ish solution to get some idea of the real usage until we get an improved analytics setup
export const getDataDomains = (
  topDomain: string,
  isAuthenticated: boolean,
  email?: string,
  roles?: Role[],
  siteDomain?: string
): string[] => {
  if (isBibsentEmail(email) || isRediaEmail(email) || hasAdminRole(roles)) {
    // Intern trafikk fra Biblioteksentralen og Redia
    return [`intern.${topDomain}`];
  }
  if (isAuthenticated) {
    // Innloggede brukere
    return [`editor.${topDomain}`];
  }
  if (siteDomain) {
    // Logger både til eget dashboard for biblioteket og til felles dashboard for alle bibliotek
    // https://plausible.io/docs/plausible-script#is-there-a-roll-up-view
    return [siteDomain, `felles.${topDomain}`];
  }

  return [topDomain];
};

export function PlausibleSetup() {
  const siteDomain = useRouter().query.site as string | undefined;
  const { loading, error, email, roles, isAuthenticated } = useSanityAuth();

  // Don't mount until we are ready to check the user
  if (loading || error || isE2eTestEmail(email)) return null;

  const topDomain = getPlausibleDomain();
  const dataDomains = getDataDomains(topDomain, isAuthenticated, email, roles, siteDomain);

  return <Script defer data-domain={dataDomains.join(",")} src="https://plausible.io/js/plausible.local.js" />;
}

export function getPlausibleDomain(): string {
  if (isProductionDataset && !isDevelopment()) return "bibliotek.io";

  if (isDevelopment()) return "bibliotek-local.io";

  return "bibliotek-test.io";
}

type ExtraData = Record<string, string | number | boolean>;

// https://plausible.io/docs/custom-event-goals
export function logEvent(event: string, extraData?: ExtraData) {
  try {
    /** @ts-ignore */
    typeof plausible !== "undefined" && plausible(event, extraData ? { props: extraData } : undefined);
  } catch (e) {
    console.error(e);
  }
}

export function logClick(label: string, extraData: ExtraData = {}) {
  logEvent("Click", { label, ...extraData });
}

export function logAdminClick(label: string, extraData: ExtraData = {}) {
  logEvent("AdminClick", { label, ...extraData });
}
